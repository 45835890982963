<template>
  <div>
    <el-row class="tac">
      <el-col :span="6" style="border-right: solid 1px #e6e6e6; height: 100vh">
        <el-menu
          class="el-menu-vertical-demo"
          :router="true"
          :default-active="activeIndex"
        >
          <el-menu-item index="/deviceEdit/preRecordEdit"> 预录 </el-menu-item>
          <el-menu-item index="/deviceEdit/netEdit"> 网络 </el-menu-item>
          <el-menu-item index="/deviceEdit/system"> 系统 </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="18">
        <router-view
          :form="form"
          :mqttClient="mqttClient"
          :ifconfigform="ifconfigform"
          :id="id"
          :version="version"
          :pushServeIp="pushServeIp"
        >
        </router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "/deviceEdit/preRecordEdit",
    };
  },
  name: "deviceEdit",
  props: {
    version: String,
    id: String,
    mqttClient: Object,
    form: Object,
    ifconfigform: Object,
    pushServeIp: String,
  },
};
</script>

<style>
.progressWrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100vh;
  z-index: 200;
}
.edit-row {
  text-align: left;
  margin-left: 9px;
  margin-top: 18px;
}
</style>