<template>
  <div style="margin-top: 18px;position:relative">
    <el-form label-width="70px" size="mini" class="customForm">
      <el-form-item label="获取方式" style="text-align: left">
        <el-select v-model="ifconfigform.byDHCP">
          <el-option
            v-for="item in ipFrom"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="ip">
        <el-input
          v-model="ifconfigform.ip"
          :disabled="ifconfigform.byDHCP"
        ></el-input>
      </el-form-item>
      <el-form-item label="子网掩码">
        <el-input
          v-model="ifconfigform.mask"
          :disabled="ifconfigform.byDHCP"
        ></el-input>
      </el-form-item>
      <el-form-item label="网关">
        <el-input
          v-model="ifconfigform.gateway"
          :disabled="ifconfigform.byDHCP"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-button
      @click="setifconfig"
      type="primary"
      style="margin-top: 40px; position: absolute; left: 240px"
      >设置</el-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      ipFrom: [
        {
          value: 0,
          label: "手动填写",
        },
        {
          value: 1,
          label: "自动获取",
        },
      ],
    };
  },
  props: {
    id: String,
    mqttClient: Object,
    ifconfigform: Object,
  },
  methods: {
    getIfconfig() {
      var data = { function: "getIfconfig" };
      var topic = "device/get/" + this.$props.id;
      this.$props.mqttClient.publish(topic, JSON.stringify(data));
    },

    setifconfig() {
      var data = { function: "setIfconfig", params: this.$props.ifconfigform };
      var topic = "device/set/" + this.$props.id;
      this.$props.mqttClient.publish(topic, JSON.stringify(data));
    },
  },
  mounted() {
    this.getIfconfig();
  },
};
</script>

<style lang="less" scoped>
.customForm /deep/ .el-input {
  width: 200px;
}

.customForm /deep/ .el-form-item__content {
  text-align: left;
}
</style>