import Vue from 'vue'
import VueRouter from 'vue-router'
import ChannelEdit from '../views/ChannelEdit.vue'
import Preview from '../views/PreView.vue'
import DeviceEdit from '../views/DeviceEdit.vue'
import preRecordEdit from '../components/preRecordEdit.vue'
import netEdit from '../components/netEdit.vue'
import System from '../components/System.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ChnanelEdit',
    component: ChannelEdit
  },
  {
    path: '/preview',
    name: 'preview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Preview
  },
  {
    path:'/deviceEdit',
    name:'deviceEdit',
    component: DeviceEdit,
    children: [
      {
        path: 'preRecordEdit',
        name: 'preRecordEdit',
        component: preRecordEdit
      },
      {
        path: 'netEdit',
        name: 'netEdit',
        component: netEdit,
      },
      {
        path: 'system',
        name: 'system',
        component: System,
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
