<template>
  <div>
    <div class="progressWrapper" v-if="isUpdate">
      <div v-bind:style="processStyle">
        <el-progress
          :stroke-width="16"
          :percentage="proPercent"
          :status="status"
          type="circle"
        ></el-progress>
        <i v-if="status == 'success'" style="font-size: 12px">{{ tipMsg }}</i>
      </div>
    </div>
    <el-form label-width="100px" size="mini" class="customForm" style="margin-top:10px;">
      <el-form-item label="推流服务器ip"
        ><el-input v-model="pushServeIp"></el-input
        ><el-button style="margin-left: 10px" @click="onChangeClick"
          >修改</el-button
        ></el-form-item
      >
    </el-form>
    <div class="edit-row">
      <b>版本：</b>
      <i>{{ version }}</i>
      <el-button
        size="mini"
        style="margin-left: 5px"
        v-if="updateButtonisVisible"
        @click="update"
        type="primary"
        plain
        >升级</el-button
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
var apiServer = "http://39.99.233.24:3000";
var webSocketAddress = "ws://39.99.233.24:3000";
export default {
  data() {
    return {
      updateButtonisVisible: false,
      isUpdate: false,
      websocket: {},
      proPercent: 0,
      status: "",
      lastVersion: "",
      processStyle: {
        margin: "0 auto",
        top: "40%",
        width: "126px",
        position: "relative",
      },
      tipMsg: "",
    };
  },
  props: {
    version: String,
    mqttClient: Object,
    id: String,
    pushServeIp: String,
  },
  methods: {
    get_last_version() {
      let url = apiServer + "/device/version";
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((res) => {
            resolve(res.data.version);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPushServeIP() {
      var data = { function: "getPushServe" };
      var topic = "device/get/" + this.$props.id;
      this.$props.mqttClient.publish(topic, JSON.stringify(data));
    },

    setPushServeIP() {
      var data = { function: "setPushServe", params: {ip:this.$props.pushServeIp} };
      var topic = "device/set/" + this.$props.id;
      this.$props.mqttClient.publish(topic, JSON.stringify(data));
    },

    onChangeClick() {
      this.setPushServeIP();
    },
    update() {
      var url = webSocketAddress + "/getProcess/" + this.$props.id;
      this.websocket = new WebSocket(url);
      this.websocket.onopen = this.open;
      this.websocket.onmessage = this.getMessage;
      var topic = "device/update/" + this.$props.id;
      var data = { version: this.$props.version };
      this.$props.mqttClient.publish(topic, JSON.stringify(data));
    },
    open() {
      console.log("socket连接成功");
      this.isUpdate = true;
    },
    getMessage(msg) {
      console.log(msg.data);
      var mlist = msg.data.split("&");
      mlist.forEach((item) => {
        if (item.indexOf("|") == -1) {
          if (item.indexOf("reboot") != -1) {
            this.websocket.close();
            this.isUpdate = false;
            this.proPercent = 0;
            this.status = "";
            this.updateButtonisVisible = false;
            this.$emit("update:version", this.lastVersion);
          }
        } else {
          var list = item.split("|");
          var dn = list[0].split(".")[0];
          var dt = list[1].split(".")[0];
          this.proPercent = parseInt(((dn * 1) / dt) * 1 * 100);
          if (dn == dt) {
            this.status = "success";
            this.tipMsg = "下载完成，等待重启";
          }
        }
      });
    },
  },
  mounted() {
    var that = this;
    this.get_last_version()
      .then((version) => {
        this.lastVersion = version;
        if (that.$props.version == version) {
          that.updateButtonisVisible = false;
        } else {
          that.updateButtonisVisible = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.getPushServeIP();
  },
};
</script>

<style lang="less" scoped>
.edit-row {
  text-align: left;
  margin-left: 9px;
  margin-top: 18px;
}

.progressWrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100vh;
  z-index: 200;
}

.customForm /deep/ .el-input {
  width: 200px;
}

.customForm /deep/ .el-form-item__content {
  text-align: left;
}
</style>
