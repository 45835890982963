<template>
  <el-dialog
    title="添加通道"
    width="80%"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    @open="onDialogOpen"
    @close="handleClose"
  >
    <el-form
      label-width="90px"
      size="mini"
    >
      <el-form-item label="通道号">
        <el-select
          v-model="channelId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :value="item.value"
            :label="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="通道类型"
        v-if="!isAddONVIF"
      >
        <el-select
          v-model="channelType"
          placeholder="通道类型"
          style="width:100px;margin-right:18px"
        >
          <el-option
            v-for="item in channelTypeDes"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          >
          </el-option>
        </el-select>
        <el-checkbox v-model="isNVR">NVR</el-checkbox>
      </el-form-item>
      <el-form-item
        label="IP"
        :required="true"
      >
        <el-input
          v-model="ip"
          :disabled="isAddONVIF"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="远程通道号"
        v-if="!isAddONVIF"
      >
        <el-input
          v-model="remouteChannel"
          :disabled="!isNVR"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="账户">
        <el-input v-model="account">
        </el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input
          v-model="password"
          type="password"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="通道名">
        <el-input v-model="channelName">
        </el-input>
      </el-form-item>
    </el-form>
    <div
      style="position:relative"
      class="clearfix"
    >
      <i
        v-show="!isIp"
        style="top: 50%;position: absolute;margin-top: -8px;margin-left: 20px;color: red;"
      >ip格式不正确！</i>
      <span
        slot="footer"
        class="dialog-footer"
        style="float:right"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="add"
        >确 定</el-button>
      </span>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="subdialogVisible"
      :append-to-body="true"
      width="50%"
    >
      <span>{{tooltips}}</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="closeDialog">取 消</el-button>
        <el-button
          type="primary"
          @click="subdialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      account: "",
      password: "",
      channelName: "",
      remouteChannel: 1,
      isNVR: false,
      ip: "",
      options: [
        {
          value: 1,
          disabled: false,
        },
        {
          value: 2,
          disabled: false,
        },
        {
          value: 3,
          disabled: false,
        },
        {
          value: 4,
          disabled: false,
        },
      ],
      channelId: 1,
      channelType: 1,
      channelTypeDes: [
        {
          value: 1,
          label: "海康",
        },
        {
          value: 2,
          label: "大华",
        },
        {
          value: 3,
          label: "熊迈",
        },
        {
          value: 4,
          label: "中维世纪",
        },
        {
          value: 5,
          label: "宇视",
        },
        {
          value: 6,
          label: "天地伟业",
        },
      ],
      subdialogVisible: false,
      tooltips: "",
      disEdit: true,
      isIp: true,
    };
  },
  props: {
    dialogVisible: Boolean,
    onvifip: String,
    existChanel: Array,
    mqttClient: Object,
    id: String,
    reply: Object,
    isAddONVIF: Boolean,
  },
  methods: {
    handleClose() {
      this.isIp = true;
      this.$emit("dialog-close");
    },
    add() {
      this.isIp = this.isValidIP(this.ip);
      if (!this.isIp) {
        return;
      }
      var topic = "device/set/" + this.$props.id;
      var data = { function: "addChannel" };
      var params = {
        ip: this.ip,
        type: this.channelType,
        username: this.account,
        password: this.password,
        channelname: this.channelName,
        channelnum: this.channelId,
        rchannel: this.remouteChannel * 1,
      };
      data.params = params;
      this.$props.mqttClient.publish(topic, JSON.stringify(data));
    },
    onDialogOpen() {
      var i;
      for (i = 0; i < 4; i++) {
        if (this.options[i].disabled == false) {
          this.channelId = this.options[i].value;
          break;
        }
      }
      if (i == 4) {
        this.channelId = "通道已满";
      }
      if(this.$props.isAddONVIF){
        this.ip = this.$props.onvifip;
        this.channelType = 0;
      }
    },
    isValidIP(ip) {
      var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
      return reg.test(ip);
    },
    closeDialog() {
      this.$emit("update:dialogVisible", false);
    },
  },
  watch: {
    reply: {
      handler(val) {
        var code = val.code;
        if (code == undefined) {
          return;
        }
        switch (code) {
          case 0:
            {
              this.subdialogVisible = true;
              this.tooltips = "添加成功";
              let topic = "device/get/" + this.id;
              var data = { function: "getChannelList" };
              this.$props.mqttClient.publish(topic, JSON.stringify(data));
            }
            break;
          case 1:
            {
              this.subdialogVisible = true;
              this.tooltips = "通道不在线";
            }
            break;
          case 3:
            {
              this.subdialogVisible = true;
              this.tooltips = "摄像头已添加";
            }
            break;
          case 6:
            {
              this.subdialogVisible = true;
              this.tooltips = "通道已绑定设备";
            }
            break;
           case 5:
            {
              this.subdialogVisible = true;
              this.tooltips = "账户或密码错误";
            }
            break; 
          default:
            break;
        }
      },
      deep: true,
    },
    existChanel: function (val, oldval) {
      oldval.forEach((item) => {
        this.options[item.id - 1].disabled = false;
      });
      val.forEach((item) => {
        this.options[item.id - 1].disabled = true;
      });
    },
  }
};
</script>

<style>
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
</style>