<template>
  <div style="position:relative;">
    <div class="edit-row">
        <template>
            <el-radio v-model="form.preRecord" label="0"> 
              不预录
            </el-radio>
            <br>
            <el-radio v-model="form.preRecord" label="1" style="margin-top:18px"> 
              预录
            </el-radio>
            <el-input size="mini" style="width:120px" v-model="form.recordTime">
            </el-input>
        </template>
    </div>
    <div class="edit-row">
        <template>
          <el-radio  v-model="form.pullRadio" label="0" :disabled="form.preRecord == 0">布防预录</el-radio>
          <br>
          <el-radio  v-model="form.pullRadio" label="1" style="margin-top:18px" :disabled="form.preRecord == 0">定时预录</el-radio>
        </template>
        <br>
        <template>
          <div style="margin-top:18px">
            <el-time-select v-model="form.startTime" :picker-options="{start:'00:00',step:'00:15',end:'24:00'}" style="width:120px" placeholder="开始时间" size="mini" :disabled="!timeselectEditalbe"> 
            </el-time-select>
            -
            <el-time-select v-model="form.endTime" :picker-options="{start:'00:00',step:'00:15',end:'24:00'}" style="width:120px" placeholder="结束时间" size="mini" :disabled="!timeselectEditalbe">
            </el-time-select>
          </div>
        </template>
    </div>
    <el-button @click="onEditButton" type="primary" style="margin-top: 40px; position: absolute; left: 240px">设置</el-button>
  </div>
</template>

<script>
export default {
    data(){
      return{

      }
    },
    props:{
      form: Object,
      mqttClient:Object,
      id:String
    },
    methods: {
    timeToSec(time) {
      var hm = time.split(":");
      var s = parseInt(hm[0]) * 60 * 60 + parseInt(hm[1]) * 60;
      return s;
    },
    get_device_Option() {
      var data = { function: "getOption" };
      var topic = "device/get/" + this.$props.id;
      this.$props.mqttClient.publish(topic, JSON.stringify(data));
    },
    onEditButton() {
      var startSec = this.timeToSec(this.form.startTime);
      var endSec = this.timeToSec(this.form.endTime);
      var topic = "device/set/" + this.$props.id;
      var params = {
        preRecord: this.form.preRecord * 1,
        recordTime: this.form.recordTime,
        byTime: this.form.pullRadio * 1,
        startTime: startSec,
        endTime: endSec,
      };
      var data = { function: "setOption", params: params };
      this.$props.mqttClient.publish(topic, JSON.stringify(data));
    },
  },
  mounted() {
    this.get_device_Option();
  },
  computed:{
    timeselectEditalbe(){
      if(this.form.preRecord==1 && this.form.pullRadio == 1){
        return true;
      }
      else{
        return false;
      }
    }
  }
}
</script>

<style>
.edit-row {
  text-align: left;
  margin-left: 9px;
  margin-top: 18px;
}
</style>