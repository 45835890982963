<template>
  <div class="clearfix">
    <div style="text-align:left">
      <h5 style="display: inline-block;margin-right:10px">推流板通道信息</h5>
      <el-button @click="addChanelFree" type="text" style="vertical-align: middle;">手动添加</el-button>
    </div>
    <template>
      <el-table
        :data="channelImfor"
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="通道号"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="ip"
          label="ip"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="rchannel"
          label="远程通道号"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="通道名"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="type"
          label="类型"
          width="120"
        >
          <template slot-scope="scope">
            <div
              class="cell"
              v-if="scope.row.type==0"
            >ONVIF</div>
            <div
              class="cell"
              v-if="scope.row.type==1"
            >海康</div>
            <div
              class="cell"
              v-if="scope.row.type==2"
            >大华</div>
            <div
              class="cell"
              v-if="scope.row.type==3"
            >熊迈</div>
            <div
              class="cell"
              v-if="scope.row.type==4"
            >中维世纪</div>
            <div
              class="cell"
              v-if="scope.row.type==5"
            >宇视</div>
            <div
              class="cell"
              v-if="scope.row.type==5"
            >天地伟业</div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="200"
        >
          <div slot-scope="scope">
            <el-popconfirm
              confirm-button-text='确定'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              title="确定删除吗？"
              @confirm="delChannel(scope.row.id)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>

          </div>
        </el-table-column>

      </el-table>
    </template>
    <h5 style="float:left">局域网摄像头（ONVIF）</h5>
    <template>
      <el-table
        :data="onvifList"
        style="width: 100%"
      >
        <el-table-column
          prop="ip"
          label="ip"
          width="180"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
        >
          <div slot-scope="scope">
            <el-button @click="addChannel(scope.row.ip)" type="text">添加</el-button>
          </div>
        </el-table-column>
      </el-table>
    </template>
    <div>
      <AddChannelDialog
        :existChanel="channelImfor"
        :dialogVisible.sync="dialogvisible"
        :onvifip="onvifIP"
        :mqttClient="mqttClient"
        :id="id"
        :reply="reply"
        :isAddONVIF="isAddONVIF"
        @dialog-close="dialogvisible = false"
      >
      </AddChannelDialog>
    </div>
  </div>
</template>

<script>
import AddChannelDialog from "@/components/AddChannelDialog";
export default {
  data() {
    return {
      dialogvisible: false,
      onvifIP: "",
      popVisible: false,
      isAddONVIF:true
    };
  },
  components: {
    AddChannelDialog,
  },
  name:"Edit",
  props: {
    mqttClient: Object,
    channelImfor: Array,
    id: String,
    onvifList: Array,
    reply: Object,
  },
  created() {
    var topic = "device/get/" + this.$props.id;
    var data = { function: "getChannelList" };
    this.$props.mqttClient.publish(topic, JSON.stringify(data));
    data = { function: "getONVIFIPC" };
    this.$props.mqttClient.publish(topic, JSON.stringify(data));
  },
  methods: {
    addChannel(ip) {
      this.isAddONVIF = true;
      this.onvifIP = ip;
      this.dialogvisible = true;
    },
    addChanelFree(){
      this.onvifIP ='';
      this.isAddONVIF = false;
      this.dialogvisible = true;
    },
    delChannel(channelId) {
      this.popVisible = false;
      var topic = "device/set/" + this.$props.id;
      var data = { function: "delChannel", params: channelId };
      this.$props.mqttClient.publish(topic, JSON.stringify(data));
    },
  },
};
</script>

<style>
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
</style>
