<template>
  <div id="app">
    <template v-if="deviceStatu == 'offline'">
      <div
        v-loading.fullscreen.lock="fullscreenLoading"
        element-loading-text="获取设备信息"
        element-loading-spinner="el-icon-loading"
      >
      </div>
      <div v-if="!fullscreenLoading" class="offline">
        设备不在线
      </div>
    </template>
    <template v-if="deviceStatu == 'online'">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        :router="true"
      >
        <el-menu-item index="/">通道管理</el-menu-item>
        <el-menu-item index="/preview">预览</el-menu-item>
        <el-menu-item index="/deviceEdit/preRecordEdit">设置</el-menu-item>
      </el-menu>
      <keep-alive include="Edit,deviceEdit">
        <router-view
          :id="id"
          :channelImfor="channel"
          :mqttClient="client"
          :onvifList="onvif"
          :reply="addChannelReply"
          :version.sync="version"
          :form="optionform"
          :ifconfigform="ifconfigform"
          :pushServeIp="pushServeIp"
        ></router-view>
      </keep-alive>
    </template>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="50%"
    >
      <span>{{replyMessage}}</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import mqtt from "mqtt";
import { getUrlKey } from "@/utils.js";
var functionType = {
  getVersion: 8,
  getChanneList: 7,
  getOnvifList: 5,
  replyAddchannel: 9,
  replyDelchannel: 11,
  getoption: 12,
  reply_setoption: 13,
  getifconfig: 14,
  reply_setifconfig: 15,
  replyGetPushserveIp:16
};
export default {
  data() {
    return {
      fullscreenLoading: true,
      activeIndex: "/",
      id: "",
      deviceStatu: "offline",
      version: "",
      lastVersion: "",
      client: {},
      channel: [],
      onvif: [],
      addChannelReply: {},
      key: 0,
      replyMessage: "",
      dialogVisible: false,
      optionform: {
        pullRadio: "0",
        startTime: "",
        endTime: "",
        preRecord: "0",
        recordTime: 10,
      },
      ifconfigform: {},
      pushServeIp:""
    };
  },
  components: {},
  mounted() {
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", this.disableBrowserBack);
    this.id = getUrlKey("deviceId");
    if(this.id == undefined){
      this.id = localStorage.getItem("id");
    }
    this.client = mqtt.connect("mqtt://39.99.233.24:9001");
    this.client.on("connect", (e) => {
      console.log(e);
    });
    this.client.on("message", (topic, payLoad) => {
      if (this.deviceStatu == "offline") {
        this.deviceStatu = "online";
      }
      //var index = topic.lastIndexOf('/');
      //var subTopic = topic.substr(0,index+1);
      var payLoadStr = "";
      var mo = {};
      switch (topic) {
        case this.replyTopic:
          {
            payLoadStr = payLoad.toString();
            mo = JSON.parse(payLoadStr);
            var type = mo.type;
            switch (type) {
              case functionType.reply_setoption:
                {
                  this.dialogVisible = true;
                  if (mo.code == 0) {
                    this.replyMessage = "设置成功";
                  } else {
                    this.replyMessage = "设置失败";
                  }
                }
                break;
              case functionType.reply_setifconfig:
                {
                  this.dialogVisible = true;
                  if (mo.code == 0) {
                    this.replyMessage = "设置成功";
                    let data = { function: "getIfconfig" };
                    let topic = "device/get/" + this.id;
                    this.client.publish(topic, JSON.stringify(data));
                  } else {
                    this.replyMessage = "设置失败";
                  }
                }
                break;
              case functionType.getVersion:
                {
                  this.version = mo.message;
                  localStorage.setItem("id", this.id);
                }
                break;
              case functionType.getChanneList:
                {
                  this.channel = JSON.parse(mo.message);
                  console.log(mo.message);
                }
                break;
              case functionType.getOnvifList:
                {
                  this.onvif = [];
                  var ipList = [];
                  let ro;
                  try {
                    ro = JSON.parse(mo.message);
                  } catch (error) {
                    console.log(error.message);
                  }
                  if (ro && ro.ipList) {
                    ipList = ro.ipList;
                  }
                  for (var i = 0; i < ipList.length; i++) {
                    this.onvif.push({ ip: ipList[i] });
                  }
                }
                break;
              case functionType.replyAddchannel:
                {
                  let ro = {};
                  ro.code = mo.code;
                  ro.message = mo.message;
                  ro.key = this.key++;
                  this.addChannelReply = ro;
                }
                break;
              case functionType.replyDelchannel:
                {
                  if (mo.code == 0) {
                    let topic = "device/get/" + this.id;
                    var data = { function: "getChannelList" };
                    this.client.publish(topic, JSON.stringify(data));
                  }
                }
                break;
              case functionType.getoption:
                {
                  let result = JSON.parse(mo.message);
                  this.optionform.pullRadio = result.byTime.toString();
                  this.optionform.preRecord = result.preRecord.toString();
                  this.optionform.startTime = this.secToTime(result.startTime);
                  this.optionform.endTime = this.secToTime(result.endTime);
                }
                break;
              case functionType.getifconfig:
                {
                  this.ifconfigform = JSON.parse(mo.message);
                  console.log(this.ifconfigform);
                }
                break;
              case functionType.replyGetPushserveIp:{
                this.pushServeIp = mo.message;
              }
              break;
              default:
                break;
            }
          }
          break;
        case this.progressTopic:
          {
            payLoadStr = payLoad.toString();
            console.log(payLoadStr);
            if (payLoadStr == "reboot finished") {
              this.client.unsubscribe(this.progressTopic, (err) => {
                if (!err) {
                  console.log("取消订阅成功:" + this.progressTopic);
                } else {
                  console.log(err);
                }
              });
              return;
            }
          }
          break;
        default:
          break;
      }
    });
    this.get_device_verson();
    this.openFullScreen1();
  },
  methods: {
    openFullScreen1() {
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.fullscreenLoading = false;
        }, 2000);
      },
    get_device_verson() {
      this.client.subscribe(this.replyTopic, (err) => {
        if (!err) {
          console.log("订阅成功:", this.replyTopic);
        }
      });
      var data = { function: "getVersion" };
      var topic = "device/get/" + this.id;
      this.client.publish(topic, JSON.stringify(data));
    },
    disableBrowserBack() {
      history.pushState(null, null, document.URL);
    },

    secToTime(sec) {
      var h = parseInt(sec / 3600);
      var m = (sec % 3600) / 60;
      var t;
      if (m < 10) {
        t = h.toString() + ":" + m.toString() + "0";
      } else {
        t = h.toString() + ":" + m.toString();
      }
      return t;
    },
  },
  computed: {
    progressTopic: function () {
      return "device/progress/" + this.id;
    },
    replyTopic: function () {
      return "device/reply/" + this.id;
    },
  },
  destroyed() {
    this.client.unsubscribe(this.replyTopic, (err) => {
      if (!err) {
        console.log("取消订阅成功:" + this.replyTopic);
      } else {
        console.log(err);
      }
    });
    window.removeEventListener("popstate", this.disableBrowserBack, false);
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.offline{
  height: 100vh;
  margin-top: 50px;
}
#top-nav {
  height: 40px;
  background-color: #42b983;
}

#top-nav button {
  float: right;
}

#nav {
  padding: 30px;
}

#nav h5 {
  margin: 0;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
