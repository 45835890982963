<template>
  <div>
    <div
      v-for="channel in channelImfor"
      :key="channel.id"
    >
      <p>
        <span>通道{{channel.id}}</span>
      </p>
      <div class="videoContainer">
        <video
          :ref='channel.mac'
          class="video-js  vjs-big-play-centered"
          playsinline
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
videojs.addLanguage("zh-CN", {
  "The media could not be loaded, either because the server or network failed or because the format is not supported.":
    "加载失败，请检查通道是否添加正确，或刷新后再播放",
});
export default {
  data() {
    return {
      player: {},
      videoFlvOptions: {
        controls: true,
        preload: "none",
        language: "zh-CN",
      },
    };
  },
  name: "preview",
  props: {
    channelImfor: Array,
    id: String,
    mqttClient:Object
  },
  mounted() {
    var that = this;
    //var topic = "device/set/" + this.$props.id;
    //var params = {
     // "channel_id":1
    //};
    //var data = { function: "preview", params: params };
    //this.$props.mqttClient.publish(topic, JSON.stringify(data));
    this.$props.channelImfor.forEach((item) => {
      that.player[item.id] = {};
      that.player[item.id].video = new videojs(
        that.$refs[item.mac][0],
        that.videoFlvOptions
      );
      that.player[item.id].video.autoplay(false);
      that.player[item.id].video.fluid(true);
      let url = "http://39.99.233.24/hls/" + this.id + "_" + item.id + ".m3u8";
      that.player[item.id].video.src(url);
    });
  },
  beforeDestroy() {
    var that = this;
    this.$props.channelImfor.forEach((item) => {
      that.player[item.id].video.dispose();
    });
  },
  methods: {},
};
</script>

<style>
.videoContainer {
  background-color: black;
}
</style>